
import DxCheckBox from "devextreme-vue/check-box";
import DxButton from "devextreme-vue/button";
import { StoreManager } from "@/components/controls/catalogueGrid/storeManager";
import { GuidHelper } from "@/helpers/guidHelper/guidHelper";
import { defineComponent } from "vue";
import { settingsId } from "@/const/idConstant";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SettingsInfoDialog from "@/components/info/SettingsInfoDialog.vue";
import EnumControl from "@/components/controls/base/EnumControl.vue";
import CatalogueSelectControl from "@/components/controls//base/CatalogueSelectControl.vue";
import IntegerControl from "@/components/controls/base/IntegerControl.vue";

export default defineComponent({
  components: {
    DxCheckBox,
    DxButton,
    FontAwesomeIcon,
    EnumControl,
    SettingsInfoDialog,
    CatalogueSelectControl,
    IntegerControl,
  },
  data() {
    return {
      savedModel: {},
      renderKey: null,
      loading: true,
      saving: false,
      changes: false,
      model: {},
      guidHelper: new GuidHelper(),
      storeManager: new StoreManager(),
      dataSource: null,
      isShowConnectionDialog: false,
      validationGroup: "baseValidationGroup",
    };
  },
  methods: {
    onShowInfo() {
      this.$refs.infoDialog.open();
    },
    onFormSubmit(e: any) {
      e.preventDefault();
      this.save();
    },
    reset() {
      this.model = { ...this.savedModel };
      this.haveChanges();
    },
    haveChanges() {
      if (!this.loading)
        this.changes = !(
          JSON.stringify(this.savedModel) == JSON.stringify(this.model)
        );
    },
    save() {
      this.saving = true;

      if (this.model.id) {
        this.storeManager.setEditModel(this.renderKey, { ...this.model });
        this.dataSource.update(this.model.id, { ...this.model }).then(
          () => {
            this.saving = false;
            this.savedModel = { ...this.model };
            this.haveChanges();
            this.reset();
          },
          () => {
            this.saving = false;
            this.haveChanges();
          }
        );
      } else {
        this.storeManager.setCreateModel(this.renderKey, { ...this.model });
        this.dataSource.insert({ ...this.model }).then(
          (res) => {
            this.saving = false;
            this.savedModel = { ...res.data };
            this.haveChanges();
            this.reset();
          },
          () => {
            this.saving = false;
            this.haveChanges();
          }
        );
      }
    },
  },
  created() {
    this.renderKey = this.guidHelper.getUniqueId(4);
    this.dataSource = this.storeManager.getStandartGridCrudStore(
      "Settings",
      this.renderKey
    );
    this.dataSource.getEdit(settingsId).then((model) => {
      this.savedModel = { ...model };
      this.reset();
      this.loading = false;
      this.haveChanges();
    });
  },
  computed: {
    isShowSettings() {
      return this.$can("view", "System.Settings");
    },
  },
});
